import "../sass/main.scss";

import "slick-carousel/slick/slick";

import "./script-modal";

jQuery(document).ready(function () {
  jQuery('#menu-main-burger').click(function () {
    jQuery('#menu-main').slideToggle();
  });
});
